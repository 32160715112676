import { Injectable } from '@angular/core';
import { AppInitializerCustomBaseService } from '@primavera/services';

@Injectable()
export class CustomInitService extends AppInitializerCustomBaseService {
	override async initialize(): Promise<void> {
		this.addChat();
	}

	private addChat(): void {
		const node: HTMLScriptElement = document.createElement('script');
		const uid = '15d8d882db614c7b8473de6b3b580700';

		node.append(`window.GPTTConfig={uuid:"${uid}"}`);
		node.type = 'text/javascript';
		document.getElementsByTagName('head')[0].appendChild(node);

		const script: HTMLScriptElement = document.createElement('script');
		script.type = 'text/javascript';
		script.defer = true;
		script.src = 'https://app.gpt-trainer.com/widget-asset.min.js';

		document.getElementsByTagName('head')[0].appendChild(script);
	}
}
